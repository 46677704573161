import { TipoDistribuidora } from 'config/tipoDistribuidoraEnum';
import { TipoDistribuidoraNombre } from 'config/tipoDistribuidoraNombreEnum';
import { SetStateAction } from 'react';
import { esDatoValido } from './tramitesUtils';

/**
 * Variable que guarda temporalmente la data de la empresa y es usada en la función obtenerEmpresa
 */
let empresa = '';

/**
 * Variable que guarda temporalmente la data de la url de pago  y es usada en la función obtenerURLPago
 */
let empresaURLPago = '';

/**
 * Variable que guarda temporalmente la data de la url de actualizar datos  y es usada en la función obtenerURLActualizar
 */
let empresaURLActualizar = '';

/**
 * Variable que guarda temporalmente la data de la url de la app de android  y es usada en la función obtenerURLAndroid
 */
let empresaURLAndroid = '';

/**
 * Variable que guarda temporalmente la data de la url de la app de iphone  y es usada en la función obtenerURLApple
 */
let empresaURLApple = '';

/**
 * Funcion para obtener el nombre de la distribuidora
 * @param codEmpresa codigo de la distribuidora
 * @returns
 */
const manejadorEmpresa = (codEmpresa: string): any => {
  switch (codEmpresa) {
    case TipoDistribuidora.SAESA:
      return TipoDistribuidoraNombre.SAESA;
    case TipoDistribuidora.FRONTEL:
      return TipoDistribuidoraNombre.FRONTEL;
    case TipoDistribuidora.EDELAYSEN:
      return TipoDistribuidoraNombre.EDELAYSEN;
    default:
      return TipoDistribuidoraNombre.SAESA;
  }
};

/**
 * Funcion para obtener la URL de pago para cada distribuidora
 * @param codEmpresa codigo de la distribuidora
 * @returns
 */
const manejadorURLPago = (codEmpresa: string): any => {
  switch (codEmpresa) {
    case TipoDistribuidora.SAESA:
      return `${process.env.REACT_APP_ASSETS_URL_PAGO_SAESA}`;
    case TipoDistribuidora.FRONTEL:
      return `${process.env.REACT_APP_ASSETS_URL_PAGO_FRONTEL}`;
    case TipoDistribuidora.EDELAYSEN:
      return `${process.env.REACT_APP_ASSETS_URL_PAGO_EDELAYSEN}`;
    default:
      return `${process.env.REACT_APP_ASSETS_URL_PAGO_SAESA}`;
  }
};

/**
 * Funcion para obtener la URL de actualizar de cada distribuidora
 * @param codEmpresa codigo de la distribuidora
 * @returns
 */
const manejadorURLActualizar = (codEmpresa: string): any => {
  switch (codEmpresa) {
    case TipoDistribuidora.SAESA:
      return `${process.env.REACT_APP_ASSETS_URL_ACTUALIZAR_DATOS_SAESA}`;
    case TipoDistribuidora.FRONTEL:
      return `${process.env.REACT_APP_ASSETS_URL_ACTUALIZAR_DATOS_FRONTEL}`;
    case TipoDistribuidora.EDELAYSEN:
      return `${process.env.REACT_APP_ASSETS_URL_ACTUALIZAR_DATOS_EDELAYSEN}`;
    default:
      return `${process.env.REACT_APP_ASSETS_URL_ACTUALIZAR_DATOS_SAESA}`;
  }
};

/**
 * Funcion para obtener la URL de la app de android de cada distribuidora
 * @param codEmpresa codigo de la distribuidora
 * @returns
 */
const manejadorURLAndroid = (codEmpresa: string): any => {
  switch (codEmpresa) {
    case TipoDistribuidora.SAESA:
      return `${process.env.REACT_APP_ASSETS_URL_ANDROID_APP_SAESA}`;
    case TipoDistribuidora.FRONTEL:
      return `${process.env.REACT_APP_ASSETS_URL_ANDROID_APP_FRONTEL}`;
    case TipoDistribuidora.EDELAYSEN:
      return `${process.env.REACT_APP_ASSETS_URL_ANDROID_APP_EDELAYSEN}`;
    default:
      return `${process.env.REACT_APP_ASSETS_URL_ANDROID_APP_SAESA}`;
  }
};

/**
 * Funcion para obtener la URL de la app de iphone de cada distribuidora
 * @param codEmpresa codigo de la distribuidora
 * @returns
 */
const manejadorURLApple = (codEmpresa: string): any => {
  switch (codEmpresa) {
    case TipoDistribuidora.SAESA:
      return `${process.env.REACT_APP_ASSETS_URL_IPHONE_APP_SAESA}`;
    case TipoDistribuidora.FRONTEL:
      return `${process.env.REACT_APP_ASSETS_URL_IPHONE_APP_FRONTEL}`;
    case TipoDistribuidora.EDELAYSEN:
      return `${process.env.REACT_APP_ASSETS_URL_IPHONE_APP_EDELAYSEN}`;
    default:
      return `${process.env.REACT_APP_ASSETS_URL_IPHONE_APP_SAESA}`;
  }
};

/**
 * Maneja el logo al seleccionar un tipo de servicio en el selector
 * @param tipoEmpresa codigo de la distribuidora
 * @returns string
 */
export const obtenerEmpresa = (tipoEmpresa: string | null): SetStateAction<string> => {
  if (esDatoValido(tipoEmpresa)) {
    empresa = manejadorEmpresa(tipoEmpresa!);
  } else {
    empresa = TipoDistribuidoraNombre.SAESA;
  }
  return empresa;
};

/**
 * Maneja el logo al seleccionar un tipo de servicio en el selector
 * @param tipoEmpresa codigo de la distribuidora
 * @returns string
 */
export const obtenerURLPago = (tipoEmpresa: string | null): SetStateAction<string> => {
  if (esDatoValido(tipoEmpresa)) {
    empresaURLPago = manejadorURLPago(tipoEmpresa!);
  } else {
    empresaURLPago = `${process.env.REACT_APP_ASSETS_URL_PAGO_SAESA}`;
  }
  return empresaURLPago;
};

/**
 * Maneja el logo al seleccionar un tipo de servicio en el selector
 * @param tipoEmpresa codigo de la distribuidora
 * @returns string
 */
export const obtenerURLActualizar = (tipoEmpresa: string | null): SetStateAction<string> => {
  if (esDatoValido(tipoEmpresa)) {
    empresaURLActualizar = manejadorURLActualizar(tipoEmpresa!);
  } else {
    empresaURLActualizar = `${process.env.REACT_APP_ASSETS_URL_ACTUALIZAR_DATOS_SAESA}`;
  }
  return empresaURLActualizar;
};

/**
 * Maneja el logo al seleccionar un tipo de servicio en el selector
 * @param tipoEmpresa codigo de la distribuidora
 * @returns string
 */
export const obtenerURLAndroid = (tipoEmpresa: string | null): SetStateAction<string> => {
  if (esDatoValido(tipoEmpresa)) {
    empresaURLAndroid = manejadorURLAndroid(tipoEmpresa!);
  } else {
    empresaURLAndroid = `${process.env.REACT_APP_ASSETS_URL_ANDROID_APP_SAESA}`;
  }
  return empresaURLAndroid;
};

/**
 * Maneja el logo al seleccionar un tipo de servicio en el selector
 * @param tipoEmpresa codigo de la distribuidora
 * @returns string
 */
export const obtenerURLApple = (tipoEmpresa: string | null): SetStateAction<string> => {
  if (esDatoValido(tipoEmpresa)) {
    empresaURLApple = manejadorURLApple(tipoEmpresa!);
  } else {
    empresaURLApple = `${process.env.REACT_APP_ASSETS_URL_IPHONE_APP_SAESA}`;
  }
  return empresaURLApple;
};
