import { Loading } from '@saesa/ui-kit-front';
import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HOME } from 'root-page';

const RouterConfig = ({ path }: { path: string }) => {
  /**
   * Permite cargar de manera lazy el componente home
   */
  const PaginaTramites = lazy(() => import('pages/Tramites/Home'));

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={`${path}${HOME}`}>
          <PaginaTramites />
        </Route>
        <Route path="*" component={PaginaTramites} />
      </Switch>
    </Suspense>
  );
};

export default RouterConfig;
