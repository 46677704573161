export const GRUPOSAESA_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/grupo-saesa-logo.svg`
export const SAESA_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/saesa-logo-blanco.svg`
export const FRONTEL_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/frontel-logo-blanco.svg`
export const EDELAYSEN_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/edelaysen-logo-blanco.svg`;
export const LUZOSORNO_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/luz-osorno-logo-blanco.svg`;











