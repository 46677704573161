import { createStyles, makeStyles, Theme } from '@material-ui/core';

const HeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      height: 'inherit',
    },
    logo: {
      width: '30%',
      height: '100%',

      [theme.breakpoints.down('sm')]: {
        width: '30%',
        height: '100%',
      },
    },
    parrafoHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
      gap: '6px',
    },
    parrafoBlancoTitulo: {
      color: '#FFFFFF',
      fontFamily: 'Neutrif Studio',
      fontWeight: 700,
      fontSize: '21px',
      lineHeight: '26px',
      paddingRight: '6px',
    },

    parrafoAmarilloTitulo: {
      color: 'yellow',
      fontFamily: 'Neutrif Studio',
      fontWeight: 700,
      fontSize: '21px',
      lineHeight: '26px',
      paddingRight: '4px',
    },

    parrafoBlancoTexto: {
      color: '#FFFFFF',
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
    },
    tituloParrafo: {
      display: 'flex',
      // wordSpacing: '0.25em',
    },

    parrafoTitulo: {},

    mostrarElemento: {
      display: 'block',
    },
    esconderElemento: {
      display: 'none',
      position: 'absolute',
      visibility: 'hidden',
    },
    item: {
      listStyleType: 'none',
      marginRight: '2.375rem',
      cursor: 'pointer',
      fontSize: '0.875rem',
      '&:last-child': {
        marginRight: '0',
      },
    },
    link: {
      color: 'var(--primario-azul-oscuro)',
      textDecoration: 'none',
      fontSize: '1.125rem',
      [theme.breakpoints.down('sm')]: {},
    },
    outlined: {
      color: 'var(--primario-morado)',
      backgroundColor: 'var(--blanco)',
      border: '2px solid var(--primario-morado)',
      borderWidth: '2px',
      '&:hover': {
        color: 'var(--primario-morado)',
        border: '2px solid var(--primario-morado)',
        backgroundColor: 'var(--hover-secundary)',
      },
    },
    menuItemActive: {
      display: 'none',
    },
  })
);

export default HeaderStyles;
