import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTramites from './en/tramites.json';
import enErrors from './en/errors.json';
import esTramites from './es/tramites.json';
import esErrors from './es/errors.json';

export const resources = {
  en: {
    tramites: enTramites,
    errors: enErrors,
  },
  es: {
    tramites: esTramites,
    errors: esErrors,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'es',
  resources,
  ns: ['tramites'],
});
