import { EDELAYSEN_LOGO, FRONTEL_LOGO, SAESA_LOGO } from 'assets/images';
import { TipoDistribuidora } from 'config/tipoDistribuidoraEnum';
import { SetStateAction } from 'react';
import { esDatoValido } from './tramitesUtils';

/**
 * Variable que guarda temporalmente la data del logo y es usada en la función obtenerLogo
 */
let logo = '';

/**
 *
 * @param codEmpresa codigo de la distribuidora
 * @returns string
 */
const manejarLogo = (codEmpresa: string): any => {
  switch (codEmpresa) {
    case TipoDistribuidora.SAESA:
      return SAESA_LOGO;
    case TipoDistribuidora.FRONTEL:
      return FRONTEL_LOGO;
    case TipoDistribuidora.EDELAYSEN:
      return EDELAYSEN_LOGO;
    default:
      return SAESA_LOGO;
  }
};

/**
 * Maneja el logo al seleccionar un tipo de servicio en el selector
 * @param tipoEmpresa codigo de la distribuidora
 * @returns string
 */
export const obtenerLogo = (tipoEmpresa: string | null): SetStateAction<string> => {
  if (esDatoValido(tipoEmpresa)) {
    logo = manejarLogo(tipoEmpresa!);
  } else {
    logo = SAESA_LOGO;
  }
  return logo;
};
