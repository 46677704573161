import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';
import useStyles from './HeaderStyles';

const Header = ({ className }: { className?: string }) => {
  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();

  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = useStyles();

  const logo = useSelector((state: RootState) => state.tramites.distribuidora);

  return (
    <header className={className}>
      <div className={styles.logo}>
        <img src={logo.urlLogo} alt="" />
      </div>

      <div className={styles.parrafoHeader}>
        <div className={styles.tituloParrafo}>
          <Typography className={styles.parrafoBlancoTitulo}>
            {t('tramites:paginas.tramites.home.header.titulo.tituloPrimeraParte')}
          </Typography>
          <Typography className={styles.parrafoAmarilloTitulo}>
            {t('tramites:paginas.tramites.home.header.titulo.tituloSegundaParte')}
          </Typography>
          <Typography className={styles.parrafoBlancoTitulo}>
            {t('tramites:paginas.tramites.home.header.titulo.tituloTerceraParte')}
          </Typography>
        </div>
        <div className={styles.parrafoTitulo}>
          <Typography className={styles.parrafoBlancoTexto}>{t('tramites:paginas.tramites.home.header.parrafo')}</Typography>
        </div>
      </div>
    </header>
  );
};

Header.defaultProps = {
  className: '',
};
export default Header;
