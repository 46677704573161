import { Header } from 'components';
import { QueryParams } from 'config';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  actualizarEmpresa,
  actualizarLogo,
  actualizarURLPago,
  actualizarURLActualizacion,
  actualizarURLAndroid,
  actualizarURLApple,
} from 'store';
import { obtenerLogo, obtenerEmpresa, obtenerURLActualizar, obtenerURLApple, obtenerURLAndroid, obtenerURLPago } from 'utils';
import { esDatoValido } from 'utils/tramitesUtils';

import Routes from './navigation/RouterConfig';
import useStyles from './RootStyles';

const Root = ({ path }: { path: string }) => {
  /**
   * Uso del i18n para traducciones
   */
  const styles = useStyles();

  const dispatch = useDispatch();

  /**
   * Variable que maneja query params
   */
  const query = QueryParams();
  /**
   * valor de empresa obtenido por query params
   */
  const tipoEmpresa = query.get('empresa');

  /**
   * Metodo que permite cargar la información del cliente
   */
  const validarNroCliente = () => {
    let actualizarLogoBody = {
      urlLogo: obtenerLogo(null),
    };
    let actualizarEmpresaBody = {
      empresa: obtenerEmpresa(null),
    };
    let actualizarURLPagoBody = {
      urlPago: obtenerURLPago(null),
    };
    let actualizarURLActualizacionBody = {
      urlActualizar: obtenerURLActualizar(null),
    };
    let actualizarURLAndroidBody = {
      urlAndroid: obtenerURLAndroid(null),
    };
    let actualizarURLAppleBody = {
      urlApple: obtenerURLApple(null),
    };
    if (esDatoValido(tipoEmpresa)) {
      actualizarLogoBody = {
        urlLogo: obtenerLogo(tipoEmpresa),
      };
      actualizarEmpresaBody = {
        empresa: obtenerEmpresa(tipoEmpresa),
      };
      actualizarURLPagoBody = {
        urlPago: obtenerURLPago(tipoEmpresa),
      };
      actualizarURLActualizacionBody = {
        urlActualizar: obtenerURLActualizar(tipoEmpresa),
      };
      actualizarURLAndroidBody = {
        urlAndroid: obtenerURLAndroid(tipoEmpresa),
      };
      actualizarURLAppleBody = {
        urlApple: obtenerURLApple(tipoEmpresa),
      };
    }
    dispatch(actualizarLogo(actualizarLogoBody));
    dispatch(actualizarEmpresa(actualizarEmpresaBody));
    dispatch(actualizarURLPago(actualizarURLPagoBody));
    dispatch(actualizarURLActualizacion(actualizarURLActualizacionBody));
    dispatch(actualizarURLAndroid(actualizarURLAndroidBody));
    dispatch(actualizarURLApple(actualizarURLAppleBody));
  };

  /**
   * UseEffect que ejecuta la función que dispara los dispatch cuando la pagina es cargada por primera vez
   */
  useEffect(() => {
    validarNroCliente();
  }, []);

  return (
    <section className={styles.desconexion}>
      <section className={styles.contendorHeader}>
        <Header className={styles.header} />
      </section>

      <section className={styles.content}>
        <Routes path={path} />
      </section>
    </section>
  );
};

export default Root;
