import { createStyles, makeStyles, Theme } from '@material-ui/core';

const RootStyles = makeStyles((theme: Theme) =>
  createStyles({
    lineaTope: {
      width: '100%',
      height: '24px',
      position: 'relative',
      background: 'var(--primario-azul-oscuro)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    menuRoot: {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
    },
    item: {
      listStyleType: 'none',
      marginRight: '2.375rem',
      cursor: 'pointer',
      fontSize: '0.875rem',
    },
    link: {
      color: 'white',
      textDecoration: 'none',
    },
    logo: {
      width: '110px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '110px',
        height: '36px',
      },
    },
    desconexion: {
      overflow: 'auto',
      minHeight: '100vh',
      position: 'relative',
      backgroundColor: '#F9F9FB ',
    },
    header: {
      backgroundColor: '#074177',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '28px 20px 77px',
      paddingRight: '0px',
      gap: '28px',
      position: 'absolute',
      zIndex: 1,
      width: '350px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '28px 20px 4.8rem',
        paddingRight: '0px',
        gap: '28px',
        minHeight: '220px',
        position: 'absolute',
        zIndex: 1,
        width: '100%',
      },
    },
    contendorHeader: {
      display: 'flex',
      justifyContent: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      paddingBottom: '0',
      background: 'linear-gradient(180deg, var(--secundario-gris-azulado) 12%, var(--blanco) 108%)',
    },
  })
);

export default RootStyles;
