import { createSlice } from '@reduxjs/toolkit';
import { TramitesState } from './tramitesState.interface';

const initialState: TramitesState = {
  /**
   * Variables guardadas en el redux
   * urlLogo: Trae la url del logo correspondiente a la distribuidora
   * empresa: Trae la distribuidora correspondiente
   * urlPago: Trae la url con la que se puede acceder a pagar la boleta de la distribuidora correspondiente
   * urlActualizar: Trae la url con la que se puede actualizar los datos el usuario de la distribuidora correspondiente
   * urlAndroid: Trae la url de la aplicación de android de la distribuidora correspondiente
   * urlApple: Trae la url de la aplicación de iphone de la distribuidora correspondiente
   */
  distribuidora: {
    urlLogo: '',
    empresa: '',
    urlPago: '',
    urlActualizar: '',
    urlAndroid: '',
    urlApple: '',
  },
};

export const tramitesSlice = createSlice({
  name: 'tramites',
  initialState,
  reducers: {
    actualizarLogo: (state, action) => {
      state.distribuidora.urlLogo = action.payload?.urlLogo || state.distribuidora.urlLogo;
    },
    actualizarEmpresa: (state, action) => {
      state.distribuidora.empresa = action.payload?.empresa || state.distribuidora.empresa;
    },
    actualizarURLPago: (state, action) => {
      state.distribuidora.urlPago = action.payload?.urlPago || state.distribuidora.urlPago;
    },
    actualizarURLActualizacion: (state, action) => {
      state.distribuidora.urlActualizar = action.payload?.urlActualizar || state.distribuidora.urlActualizar;
    },
    actualizarURLAndroid: (state, action) => {
      state.distribuidora.urlAndroid = action.payload?.urlAndroid || state.distribuidora.urlAndroid;
    },
    actualizarURLApple: (state, action) => {
      state.distribuidora.urlApple = action.payload?.urlApple || state.distribuidora.urlApple;
    },
  },
});

export const {
  actualizarLogo,
  actualizarEmpresa,
  actualizarURLPago,
  actualizarURLActualizacion,
  actualizarURLAndroid,
  actualizarURLApple,
} = tramitesSlice.actions;

export default tramitesSlice.reducer;
